.token_image {
  width: 240px;
  max-width: 100%;
  aspect-ratio: 3 / 4;
}

.gs_upper {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
}

.see_all_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  button+button {
    margin-left: 10px;
  }
}

.see_all {
  padding: 8px !important;
  white-space: wrap;
  & p {
    font-size: 11px;
    line-height: 1;
  }
}

.status {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}