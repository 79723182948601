html,
body,
div,
span,
applet,
object,
iframe,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
em {
  pointer-events: none;
}
@font-face {
  font-family: halftones;
  src: url("assets/fonts/halftones/half4.ttf");
}
@font-face {
  font-family: coffee;
  src: url("assets/fonts/halftones/coffee.ttf");
}

@font-face {
  font-family: graph35;
  src: url("assets/fonts/graph35.ttf");
}
@font-face {
  font-family: playfair;
  src: url("assets/fonts/playfair-r.ttf");
}
@font-face {
  font-family: playfairbold;
  src: url("assets/fonts/playfair-b.ttf");
}
@font-face {
  font-family: changa;
  src: url("assets/fonts/tchanga.ttf");
}
@font-face {
  font-family: mozart;
  src: url("assets/fonts/mozart.ttf");
}
@font-face {
  font-family: ebgaramond;
  src: url("assets/fonts/ebgaramond.ttf");
}
* {
  cursor: url("assets/cursors/cursor.cur"), auto;
}
.pointer:not(.disabled),
.pointer:not(.disabled):hover,
.pointer:not(.disabled) *,
label::before,
.button:not(.disabled),
.button:not(.disabled) *,
button:not(.disabled):not(.header_button) {
  cursor: url("assets/cursors/point.cur"), pointer;
}
.crosshair {
  cursor: url("assets/cursors/crosshair.cur"), crosshair;
}
.grabber {
  cursor: url("assets/cursors/grab.cur"), grab;
}
.mover {
  cursor: url("assets/cursors/move.cur"), move;
}
.texter {
  cursor: url("assets/cursors/text.cur"), text;
}
*,
*::after,
*::before,
*:placeholder-shown {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
}
html,
body,
#root {
  scroll-behavior: smooth;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  animation: fadein 0.15s linear forwards;
}
#cursor_wrapper {
  pointer-events: none;
}
#fairy_wrapper {
  pointer-events: none;
  display: none;
}
html,
body {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}
body {
  margin: 0;
  padding: 0;
  font-family: Verdana, Helvetica, sans-serif;
  letter-spacing: 0.5px;
}
:root {
  --zoom: 1;
  --initThemeColor: hsl(284, 6%, 82%);
  --initHeaderColor: hsl(0, 73%, 85%);
  
  --headerColor: hsl(0, 73%, 85%);
  --hueRotate: 0deg;
  --black: black;
  --colorgrad0: hsl(0, 0%, 100%);
  --colorgrad1: hsl(270, 6%, 94%);
  --colorgrad2: hsl(300, 3%, 87%);
  --colorgrad3: hsl(284, 6%, 82%);
  --colorgrad4: hsl(187, 7%, 75%);
  --colorgrad5: hsl(292, 4%, 62%);
  --colorgrad6: hsl(240, 3%, 52%);
  --colorgrad7: hsl(290, 4%, 27%);
  --colorgrad8: hsl(0, 0%, 5%);
}
html,
body,
#root,
#main_app {
  overscroll-behavior: none;
  overflow: hidden;
  color: var(--black);
}
p {
  color: var(--black);
  font-size: 11.5px;
  line-height: 13px;
}
svg {
  position: absolute;
  visibility: hidden;
}
body {
  background-size: cover;
}

textarea,
input[type="number"],
input[type="text"],
input[type="button"],
input[type="submit"] {
  appearance: none;
  -webkit-appearance: none;
  border-radius: 0;
}
::placeholder {
  color: var(--colorgrad3);
  opacity: 1;
}

:-ms-input-placeholder {
  color: var(--colorgrad3);
}

::-ms-input-placeholder {
  color: var(--colorgrad3);
}
::-moz-selection {
  background: #d3d2d4;
}
::selection {
  background: #d3d2d4;
}

.shake_err {
  animation-name: shake_err;
  animation-duration: 0.65s;
  animation-delay: 0.05s;
}

@keyframes shake_err {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes grayen {
  0% {
    backdrop-filter: brightness(1) grayscale(0);
  }
  20% {
    backdrop-filter: brightness(1) grayscale(0);
  }
  100% {
    backdrop-filter: brightness(0.6) grayscale(1);
  }
}
@keyframes grow {
  0% {
    height: 0;
  }
  100% {
    height: 100vh;
  }
}

// SCROLLBAR

$main-color: var(--colorgrad3);
$highlight-color: var(--colorgrad0);
$inner-shadow-color: var(--colorgrad5);
$outer-shadow-color: var(--colorgrad7);
$img-up-arrow: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAGklEQVR4AWMYxuA/SYphmETFhDX9x4mHGQAAcL4P8dQiMq8AAAAASUVORK5CYII=";
$img-down-arrow: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAQAAAD8fJRsAAAAF0lEQVQY02NgoBf4jwJxSOHQhcNAOgMAWWAP8Rv2U3UAAAAASUVORK5CYII=";
$img-left-arrow: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAQAAAD8fJRsAAAAHklEQVQY02NgoBT8xyX8H5fwf1zCpOjAYwceV1EEAAO2D/HsQ4vsAAAAAElFTkSuQmCC";
$img-right-arrow: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAQAAAD8fJRsAAAAHUlEQVQY02NgIB/8xy3xH7fEf9wS/0nUQZqrKAYAK44P8ZRmzLQAAAAASUVORK5CYII=";
$img-scrollbar-bg: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAIAAAD91JpzAAAAFElEQVQIW2M4fPz0////GYAYyAIASnoKpV3w4kgAAAAASUVORK5CYII=";
$img-scrollbar-corner: "data:image/gif;base64,R0lGODlhDAAMAJEAAAAAAP///5mZmf///yH5BAEAAAMALAAAAAAMAAwAAAIbnI8TmSF83IMSKvFWw3dnHnFV+GVGhZZXmaoFADs=";

@mixin well-border {
  border-top: 1px solid $outer-shadow-color;
  border-left: 1px solid $outer-shadow-color;
  border-right: 1px solid $main-color;
  border-bottom: 1px solid $main-color;
  box-shadow: -1px -1px 0 $inner-shadow-color, -1px 0 0 $inner-shadow-color,
    0 -1px 0 0 $inner-shadow-color, 1px 0 0 $highlight-color,
    1px 1px 0 $highlight-color, 0 1px 0 $highlight-color;
}

@mixin emboss-border {
  border-top: 1px solid $main-color;
  border-left: 1px solid $main-color;
  border-right: 1px solid $outer-shadow-color;
  border-bottom: 1px solid $outer-shadow-color;
  box-shadow: inset 1px 1px 0 0 $highlight-color,
    inset -1px -1px 0 0 $inner-shadow-color;
}

@mixin depressed-border {
  border-top: 1px solid $outer-shadow-color;
  border-left: 1px solid $outer-shadow-color;
  border-bottom: 1px solid $inner-shadow-color;
  border-right: 1px solid $inner-shadow-color;
  box-shadow: inset 1px 1px 0 0 $inner-shadow-color,
    1px 1px 0 0 $outer-shadow-color, 1px 0 0 0 $outer-shadow-color,
    0 1px 0 0 $outer-shadow-color;
}

::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  background-size: 2px 2px;
  background-position: 0 0, -1px -1px;
  background-image: linear-gradient(
      45deg,
      var(--colorgrad2) 25%,
      transparent 25%,
      transparent 75%,
      var(--colorgrad2) 75%,
      var(--colorgrad2)
    ),
    linear-gradient(
      45deg,
      var(--colorgrad2) 25%,
      transparent 25%,
      transparent 75%,
      var(--colorgrad2) 75%,
      var(--colorgrad2)
    );
  image-rendering: pixelated;
}

::-webkit-scrollbar-thumb {
  @include emboss-border;
  width: 16px;
  height: 16px;
  background-color: $main-color;
  z-index: 1;
}

::-webkit-scrollbar-corner {
  background-image: url($img-scrollbar-corner);
  background-color: $main-color;
  image-rendering: pixelated;
  background-repeat: no-repeat;
  background-position: right bottom;
  box-shadow: -1px -1px 0 $inner-shadow-color, -1px 0 0 $inner-shadow-color,
    0 -1px 0 0 $inner-shadow-color, 1px 0 0 $highlight-color,
    1px 1px 0 $highlight-color, 0 1px 0 $highlight-color;
}

::-webkit-resizer {
  background-image: url($img-scrollbar-corner);
  background-color: $main-color;
  image-rendering: pixelated;
  background-repeat: no-repeat;
  background-position: right bottom;
  box-shadow: -1px -1px 0 $inner-shadow-color, -1px 0 0 $inner-shadow-color,
    0 -1px 0 0 $inner-shadow-color, 1px 0 0 $highlight-color,
    1px 1px 0 $highlight-color, 0 1px 0 $highlight-color;
}

::-webkit-scrollbar-button {
  @include emboss-border;

  display: block;
  width: 16px;
  height: 16px;
  background-color: $main-color;
  image-rendering: pixelated;
  background-repeat: no-repeat;
  background-position: center center;

  &:active {
    background-position: 2px 2px;
  }

  &:horizontal:decrement {
    background-image: url($img-left-arrow);
  }

  &:horizontal:increment {
    background-image: url($img-right-arrow);
  }

  &:vertical:decrement {
    background-image: url($img-up-arrow);
  }

  &:vertical:increment {
    background-image: url($img-down-arrow);
  }

  &:horizontal:increment:start {
    display: none;
  }
  &:horizontal:decrement:end {
    display: none;
  }
  &:vertical:increment:start {
    display: none;
  }
  &:vertical:decrement:end {
    display: none;
  }

  &:active {
    border-top: 1px solid $inner-shadow-color;
    border-left: 1px solid $inner-shadow-color;
    border-bottom: 1px solid $inner-shadow-color;
    border-right: 1px solid $inner-shadow-color;
    box-shadow: none;
  }
}

// BUTTON

.button.disabled {
  color: var(--colorgrad5);
  text-shadow: 1px 1px 0 var(--colorgrad0);
}

.button {
  text-align: center;
  color: var(--black);
  letter-spacing: 1px;
  border: 0;
  outline: 0;
  min-width: 32px;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  align-self: center;
  padding: 6px;
  font-size: 11px;
  line-height: 11px;
  align-items: center;
  justify-content: center;
  background: var(--colorgrad3);
  box-shadow: inset -1px -1px 0 0 var(--colorgrad7),
    inset 1px 1px 0 0 var(--colorgrad0), inset -2px -2px 0 0 var(--colorgrad5),
    inset 2px 2px 0 0 var(--colorgrad2);
  & i {
    font-style: normal;
    padding: 10px 0;
    clip-path: inset(-10px -16px -1px -16px);
  }
  &:active:not(.disabled) {
    color: rgba(0, 0, 0, 0.5);
    outline: 1px dotted #000000;
    outline-offset: -5px;
    background: linear-gradient(
        45deg,
        var(--colorgrad1) 25%,
        transparent 25%,
        transparent 75%,
        var(--colorgrad1) 75%,
        var(--colorgrad1)
      ),
      linear-gradient(
        45deg,
        var(--colorgrad1) 25%,
        transparent 25%,
        transparent 75%,
        var(--colorgrad1) 75%,
        var(--colorgrad1)
      );
    background-size: 2px 2px;
    background-position: 0 0, 1px 1px;
    box-shadow: inset -1px -1px 0 0 var(--colorgrad0),
      inset 1px 1px 0 0 var(--colorgrad7), inset -2px -2px 0 0 var(--colorgrad2),
      inset 2px 2px 0 0 var(--colorgrad3);
  }
}

.buttons_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 10px 0;
  .button + .button {
    margin-left: 10px;
  }
  button,
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    padding: 8px;
  }
}

.classicLink {
  display: inline !important;
  text-decoration: underline;
  color: #0000ee;
  &:active {
    color: #ff0000;
  }
  &:visited:not(:active) {
    color: #551a8b;
  }
}
// ADDRESS BAR

.address_bar {
  display: flex;
  align-items: center;
  margin: 2px 0;
  flex-shrink: 0;
}
.address_bar_content_img {
  width: 14px;
  height: 14px;
  display: inline;
  order: 1;
  margin: -2px 4px 0 4px;
}
.address_bar_content_text {
  color: black;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  overflow: hidden;
  padding: 2px;
}
.address_bar_title {
  padding: 4px;
  color: var(--colorgrad6);
}
.address_bar_content {
  flex: 1;
  min-width: 0;
  background-color: white;
  height: 22px;
  box-shadow: inset -1px -1px 0px var(--colorgrad0),
    inset 1px 1px 0px 0px var(--colorgrad6),
    inset -2px -2px 0px var(--colorgrad4),
    inset 2px 2px 0px 0px var(--colorgrad8);
  display: inline-block;
  span {
    order: 2;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 4px;
  }
}
.address_bar_buttons {
  white-space: nowrap;
  margin-left: auto;
  order: 3;
  > div {
    display: inline-block;
  }
  img {
    width: 14px;
    height: 14px;
    display: inline;
    order: 1;
    margin: 0 4px;
  }
}
.address_bar_forward,
.address_bar_back {
  box-shadow: inset -1px -1px 0px var(--colorgrad7),
    inset 1px 1px 0px var(--colorgrad4), inset -2px -2px 0px var(--colorgrad6),
    inset 2px 2px 0px var(--colorgrad0);
  height: 18px;
  width: 20px;
  min-width: 20px;
  image-rendering: pixelated;
  background-color: var(--colorgrad3);
  position: relative;
  display: flex;
  & img {
    position: absolute;
    top: 2px;
    width: auto;
    height: auto;
  }
  &.disabled {
    opacity: 0.6;
    & img {
      opacity: 0.8;
      filter: drop-shadow(rgb(254, 254, 254) 1px 1px 0px);
    }
  }
  &:not(.disabled):active {
    outline: 1px dotted #000000;
    outline-offset: -3px;
    box-shadow: inset -1px -1px 0 0 var(--colorgrad0),
      inset 1px 1px 0 0 var(--colorgrad7), inset -2px -2px 0 0 var(--colorgrad2),
      inset 2px 2px 0 0 var(--colorgrad3);
  }
}
.hasactive:active {
  box-shadow: inset -1px -1px 0 0 var(--colorgrad0),
    inset 1px 1px 0 0 var(--colorgrad7), inset -2px -2px 0 0 var(--colorgrad2),
    inset 2px 2px 0 0 var(--colorgrad3);
}
.address_bar_go {
  box-shadow: inset -1px -1px 0px var(--colorgrad7),
    inset 1px 1px 0px var(--colorgrad4), inset -2px -2px 0px var(--colorgrad6),
    inset 2px 2px 0px var(--colorgrad0);
  height: 18px;
  width: 20px;
  min-width: 20px;
  background-color: var(--colorgrad3);
  position: relative;
  opacity: 0.6;
  & div {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 0px;
    height: 0px;
    margin-right: 0.5px;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid rgb(132, 133, 132);
    filter: drop-shadow(rgb(254, 254, 254) 1px 1px 0px);
  }
}
// WINDOW

.window_toolbar {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 24px;
  background: var(--colorgrad3);
}
.window_content {
  position: relative;
  width: 100%;
  height: calc(100% - 52px);
  padding: 2px;
  background: #fff;
  box-shadow: inset -1px -1px 0 0 var(--colorgrad2),
    inset 1px 1px 0 0 var(--colorgrad5), inset -2px -2px 0 0 var(--colorgrad3),
    inset 2px 2px 0 0 var(--colorgrad7);
}
.window_content_inner {
  position: absolute;
  width: calc(100% - 4px);
  height: calc(100% - 54px);
  overflow: auto;
  background-color: white;
}

// INPUTS

input[type="radio"] {
  opacity: 0;
  display: none;
}
input[type="radio"] + label {
  position: relative;
  padding: 1px 0px;
  padding-left: 16px;
}
input[type="radio"] + label > span,
input[type="radio"] + label > div {
  display: inline-block;
  border: 1px solid rgba(0, 0, 0, 0);
  margin: 2px;
}
input[type="radio"] + label:before {
  content: "";
  position: absolute;
  left: 0px;
  top: 1px;
  width: 20px;
  height: 12px;
  background-repeat: no-repeat;
}
input[type="radio"]:checked + label {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}
input[type="radio"]:checked:active + label > span,
input[type="radio"]:checked:active + label > div,
input[type="radio"]:checked:focus + label > span,
input[type="radio"]:checked:focus + label > div,
input[type="radio"]:checked:active:focus + label > span,
input[type="radio"]:checked:active:focus + label > div,
input[type="radio"]:checked.active + label > span,
input[type="radio"]:checked.active + label > div,
input[type="radio"]:checked.clicked + label > span,
input[type="radio"]:checked.clicked + label > div {
  border: 1px dotted var(--colorgrad8);
}
input[type="radio"]:disabled + label,
input[type="radio"].disabled + label {
  color: var(--colorgrad3);
}
input[type="radio"] + label:before {
  background-image: url("data:image/gif;base64,R0lGODlhDAAMAKIAAAAAAP///8zMzJmZmf///wAAAAAAAAAAACH5BAEAAAQALAAAAAAMAAwAAAMqSErTs6uBCVqcIQesBtCaEDAfGAaeeaZqILKqyLQyI4hhTWT3nUEKECQBADs=");
}
input[type="radio"]:checked + label:before {
  background-image: url("data:image/gif;base64,R0lGODlhDAAMAKIAAAAAAP///8zMzJmZmf///wAAAAAAAAAAACH5BAEAAAQALAAAAAAMAAwAAAMtSErTs6uBCVqcIQesBtCaEDBfhmWiZ1JooG5skJZwOA6g3QliKC4oXg+iAEESADs=");
}
input[type="radio"]:disabled + label:before,
input[type="radio"].disabled + label:before {
  background-image: url("data:image/gif;base64,R0lGODlhDAAMAKIAAAAAAP///8zMzJmZmf///wAAAAAAAAAAACH5BAEAAAQALAAAAAAMAAwAAAMpSErTs6uBCVqccAY1AFTC1n1LOJKE6aEqmorsxggCRMtEENA3vug6SAIAOw==");
}
input[type="radio"]:disabled:checked + label:before,
input[type="radio"].disabled:checked + label:before {
  background-image: url("data:image/gif;base64,R0lGODlhDAAMAKIAAAAAAP///8zMzJmZmf///wAAAAAAAAAAACH5BAEAAAQALAAAAAAMAAwAAAMtSErTs6uBCVqccAY1AFTC1i0YGIwE5REhqppourLiZ3KCAOWbEgQ5Xg/y+0ESADs=");
}
input[type="checkbox"]:not(.hidden) {
  opacity: 0;
  display: none;
}
input[type="checkbox"]:not(.hidden) + label {
  position: relative;
  padding: 1px 0px;
  padding-left: 16px;
}
input[type="checkbox"]:not(.hidden):not(.disabled) + label {
  cursor: url("assets/cursors/point.cur"), pointer;
}
input[type="checkbox"]:not(.hidden) + label > span,
input[type="checkbox"]:not(.hidden) + label > div {
  display: inline-block;
  border: 1px solid rgba(0, 0, 0, 0);
  margin: 2px;
}
input[type="checkbox"]:not(.hidden) + label:before {
  content: "";
  position: absolute;
  left: 0px;
  top: 1px;
  width: 20px;
  height: 12px;
  background-repeat: no-repeat;
}
input[type="checkbox"]:not(.hidden):checked + label {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}
input[type="checkbox"]:not(.hidden):checked:active + label > span,
input[type="checkbox"]:not(.hidden):checked:active + label > div,
input[type="checkbox"]:not(.hidden):checked:focus + label > span,
input[type="checkbox"]:not(.hidden):checked:focus + label > div,
input[type="checkbox"]:not(.hidden):checked:active:focus + label > span,
input[type="checkbox"]:not(.hidden):checked:active:focus + label > div,
input[type="checkbox"]:not(.hidden):checked.active + label > span,
input[type="checkbox"]:not(.hidden):checked.active + label > div,
input[type="checkbox"]:not(.hidden):checked.clicked + label > span,
input[type="checkbox"]:not(.hidden):checked.clicked + label > div {
  border: 1px dotted var(--colorgrad8);
}
input[type="checkbox"]:not(.hidden):disabled + label,
input[type="checkbox"]:not(.hidden).disabled + label {
  color: var(--colorgrad3);
}
input[type="checkbox"]:not(.hidden) + label:before {
  width: 13px;
  height: 13px;
  background-color: #ffffff;
  box-shadow: inset -1px -1px 0px var(--colorgrad0),
    inset 1px 1px 0px 0px var(--colorgrad6),
    inset -2px -2px 0px var(--colorgrad4),
    inset 2px 2px 0px 0px var(--colorgrad8);
}
input[type="checkbox"]:not(.hidden):checked + label:before {
  background-image: url("data:image/gif;base64,R0lGODlhBwAHAJEAAAAAAP///////wAAACH5BAEAAAIALAAAAAAHAAcAAAIMlA9nwMj9xGuLIlUAADs=");
  background-position: center;
  background-size: 7px;
}
input[type="checkbox"]:not(.hidden):disabled + label:before,
input[type="checkbox"]:not(.hidden).disabled + label:before {
  background-color: var(--colorgrad3);
}
input[type="checkbox"]:not(.hidden):disabled:checked + label:before,
input[type="checkbox"]:not(.hidden).disabled:checked + label:before {
  background-image: url("data:image/gif;base64,R0lGODlhBwAHAJEAAAAAAP///5mZmf///yH5BAEAAAMALAAAAAAHAAcAAAIMnC9nwsj9xmuLIlUAADs=");
}
input[type="range"] {
  appearance: none;
  -webkit-appearance: none;
  height: 4px;
  margin: 10px 0;
  width: calc(100% - 20px);
  background-color: white;
  box-shadow: inset -1px -1px 0px var(--colorgrad0), inset 1px 1px 0px 0px var(--colorgrad6),
    inset -2px -2px 0px var(--colorgrad4), inset 2px 2px 0px 0px var(--colorgrad8);
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  border-radius: 0;
  border: none;
  & * {
    border: none;
    border-radius: 0;
  }
}
input[type="range"] label {
  font-size: 10px;
  position: absolute;
  right: 0px;
  top: -8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}
input[type="range"]:active {
  outline: 1px dotted #000000;
  outline-offset: 4px;
}
input[type="range"]:not(.disabled)::-webkit-slider-thumb {
  cursor: url("assets/cursors/point.cur"), pointer;
}
input[type="range"]:not(.disabled)::-moz-range-thumb {
  cursor: url("assets/cursors/point.cur"), pointer;
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 10px;
  height: 18px;
  background-color: var(--colorgrad3);
  box-shadow: inset -1px -1px 0px var(--colorgrad7), inset 1px 1px 0px var(--colorgrad4),
    inset -2px -2px 0px var(--colorgrad6), inset 2px 2px 0px var(--colorgrad0);
}
input[type="range"]::-moz-range-thumb {
  width: 10px;
  height: 18px;
  background-color: var(--colorgrad3);
  box-shadow: inset -1px -1px 0px var(--colorgrad7), inset 1px 1px 0px var(--colorgrad4),
    inset -2px -2px 0px var(--colorgrad6), inset 2px 2px 0px var(--colorgrad0);
}
input[type="range"].disabled {
  &:active {
    outline: 0;
  }
}
.number-input {
  overflow: hidden;
  box-sizing: content-box;
  height: 18px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 2px;
  width: 100%;
  box-shadow: inset -1px -1px 0px var(--colorgrad0), inset 1px 1px 0px 0px var(--colorgrad6),
    inset -2px -2px 0px var(--colorgrad4), inset 2px 2px 0px 0px var(--colorgrad8);
}
.number-input input {
width: 100%;
  border: 0;
  padding: 4px;
  letter-spacing: 0.5px;
  font-size: 12px;
  background-color: white;
}

.number-input.disabled {
  background-color: var(--colorgrad3);
  input {
    color: var(--colorgrad5);
    background-color: var(--colorgrad3);
    text-shadow: 1px 1px 0 white;
  }
  & .number-btns div {
    background: none;
    &:active {
      outline: none;
      box-shadow: inset -1px -1px 0px var(--colorgrad7), inset 1px 1px 0px var(--colorgrad4),
        inset -2px -2px 0px var(--colorgrad6), inset 2px 2px 0px var(--colorgrad0);
    }
    &:first-child {
      div {
        border-bottom: 3px solid var(--colorgrad5);
        filter: drop-shadow(1px 1px 0 white);
      }
    }
    &:last-child {
      div {
        border-top: 3px solid var(--colorgrad5);
        filter: drop-shadow(1px 1px 0 white);
      }
    }
  }
}
.number-btns {
  height: 100%;
}
.number-btns div {
  background-color: var(--colorgrad3);
  box-shadow: inset -1px -1px 0px var(--colorgrad7), inset 1px 1px 0px var(--colorgrad4),
    inset -2px -2px 0px var(--colorgrad6), inset 2px 2px 0px var(--colorgrad0);
  width: 18px;
  height: 50%;
  position: relative;

  &:active {
    outline: 1px dotted #000000;
    outline-offset: -3px;
    box-shadow: inset -1px -1px 0 0 var(--colorgrad0), inset 1px 1px 0 0 var(--colorgrad7),
      inset -2px -2px 0 0 var(--colorgrad2), inset 2px 2px 0 0 var(--colorgrad3);
  }
  &:first-child {
    div {
      position: absolute;
      top: 3.5px;
      left: 6px;
      width: 0;
      height: 0;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      border-bottom: 3px solid black;
    }
  }
  &:last-child {
    div {
      position: absolute;
      top: 3.5px;
      left: 6px;
      width: 0;
      height: 0;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      border-top: 3px solid black;
    }
  }
}
input[type="number"], input[type="text"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  outline: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
.dropdown {
  z-index: 100;
  display: flex;
  flex-direction: column;
  background: white;
  transition: height 0.2s;
  overflow-y: scroll;
  overflow-x: hidden;
  box-sizing: border-box;
  position: absolute;
  border: 1px solid var(--colorgrad8);
  height: auto;
  max-height: 100px;
  width: calc(100% - 22px);
  margin-top: -1px;
}
.dropdown.closed {
  height: 0 !important;
  border: 0;
}
.dropup {
  position: relative;
  top: 0;
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding: 2px;
  width: 100%;
  box-shadow: inset -1px -1px 0px var(--colorgrad0), inset 1px 1px 0px 0px var(--colorgrad6),
    inset -2px -2px 0px var(--colorgrad4), inset 2px 2px 0px 0px var(--colorgrad8);
  height: 22px;
}
.dropup span {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 4px;
}
.dropup.disabled {
  background-color: var(--colorgrad3);
  color: var(--colorgrad5);
  text-shadow: 1px 1px 0 white;
  img {
    filter: drop-shadow(1px 1px 0 white);
  }
}
.dropup_btn {
  box-shadow: inset -1px -1px 0px var(--colorgrad7), inset 1px 1px 0px var(--colorgrad4),
    inset -2px -2px 0px var(--colorgrad6), inset 2px 2px 0px var(--colorgrad0);
  height: 18px;
  width: 20px;
  min-width: 20px;
  image-rendering: pixelated;
  background-color: var(--colorgrad3);
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAQAAAD8fJRsAAAAF0lEQVQY02NgoBf4jwJxSOHQhcNAOgMAWWAP8Rv2U3UAAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  & div {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 0px;
    height: 0px;
  }
  &.disabled {
    background: none;
    & div {
      margin-right: 0.5px;
      border-left: 3.5px solid transparent;
      border-right: 3.5px solid transparent;
      border-top: 3.5px solid var(--colorgrad5);
      filter: drop-shadow(1px 1px 0px white);
    }
  }
  &:not(.disabled):active {
    outline: 1px dotted #000000;
    outline-offset: -3px;
    box-shadow: inset -1px -1px 0 0 var(--colorgrad0), inset 1px 1px 0 0 var(--colorgrad7),
      inset -2px -2px 0 0 var(--colorgrad2), inset 2px 2px 0 0 var(--colorgrad3);
  }
}

.dropdown_option {
  span {
    line-height: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    padding: 4px;
    overflow: hidden;
    &:hover {
      padding: 3px;
      color: white;
      background-color: #0b61ff;
      border: 1px dotted darkgrey;
    }
  }
}

// TABLE

table {
  letter-spacing: 2px;
}

.table {
  border-spacing: 4px;
}

.go_table {
  border-spacing: 6px;
}

.sl_table {
  --space: 8px;
  border-spacing: var(--space);
  width: calc(100% + var(--space)*2);
  margin-top: calc(10px - var(--space));
  margin-bottom: calc(-1 * var(--space));
  thead {
    position: relative;
    top: calc(var(--space)/2);
    text-align: left;
  }
}
.gs_upper, .sl_token_upper, .go_token_upper {
  margin: 10px 0;
  gap: 10px;
}
table:not(.sl_table) {
  width: 100%;
  margin-top: 10px;
}
table,
.table {
  vertical-align: middle;
  table-layout: fixed;
  border-collapse: separate;
  & th,
  .th {
    vertical-align: middle;
    letter-spacing: 3px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  & td {
    font-size: 12px;
    min-height: 22px;
    padding: 6px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    /* border: 1px solid var(--colorgrad3); */
  }
  & tbody {
    text-transform: lowercase;
  }
  & thead td {
    text-align: center;
    min-height: 22px;
  }
}

.table {
  padding: 4px;
  box-shadow: inset -1px -1px 0 0 var(--colorgrad7),
    inset 1px 1px 0 0 var(--colorgrad0), inset -2px -2px 0 0 var(--colorgrad5),
    inset 2px 2px 0 0 var(--colorgrad2);
  background: var(--colorgrad3);
  & td {
    box-shadow: inset -1px -1px 0px var(--colorgrad0),
      inset 1px 1px 0px 0px var(--colorgrad6),
      inset -2px -2px 0px var(--colorgrad4),
      inset 2px 2px 0px 0px var(--colorgrad8);
    background: #ffffff;
  }
}
.go_table {
  padding: 1px;
  border: 1px solid #b19cd9;
  min-width: 240px;
  & td {
    border: 1px solid #b19cd9;
  }
}

// DIVIDER

.pagediv {
  margin: 0 0 4px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 3px;
  background-color: var(--colorgrad3);
  box-shadow: inset -1px -1px 0px var(--colorgrad6),
    inset 1px 1px 0px var(--colorgrad0);
}
.divider {
  height: 1px;
  border-top: 1px solid var(--colorgrad6);
  border-bottom: 1px solid var(--colorgrad0);
}
.div_bar {
  margin: 0 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 18px;
  width: 3px;
  background-color: var(--colorgrad3);
  content: "";
  box-shadow: inset -1px -1px 0px var(--colorgrad6),
    inset 1px 1px 0px var(--colorgrad0);
}

// BOX SECTION

.box_section {
  position: relative;
  border: 1px solid var(--colorgrad0);
  outline: 1px solid var(--colorgrad6);
  padding: 15px 12px 12px 12px;
  width: 100%;
  & p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.box_section_title {
  position: relative;
  top: -23px;
  left: -4px;
  span {
    background-color: var(--colorgrad3);
    position: absolute;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 0 4px;
    line-height: 14px;
    max-width: calc(90%);
  }
}
.token_box {
  padding: 10px;
  background: var(--colorgrad3);
  box-shadow: inset -1px -1px 0 0 var(--colorgrad7),
    inset 1px 1px 0 0 var(--colorgrad0), inset -2px -2px 0 0 var(--colorgrad5),
    inset 2px 2px 0 0 var(--colorgrad2);
}
.token_caption:not(#gs_inner .token_caption) {
  min-width: min-content;
}
.token_caption {
  letter-spacing: 2px;
  line-height: 12px;
  flex: 2;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  text-align: center;
  overflow: hidden;
  min-width: min-content;
  text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  b {
    letter-spacing: 3px;
    font-size: 13px;
    line-height: 14px;
  }
  h2 {
    font-size: 16px;
    margin-bottom: 5px;
    letter-spacing: 3px;
  }
  & div {
    margin: 5px;
    align-self: center;
    max-width: 100%;
    overflow: hidden;
    & span {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-top: 10px;
      line-height: 14px;
    }
    .tokenowner {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      word-break: break-word;
    }
  }
}
.token_box:active {
  outline: 1px dotted #000000;
  outline-offset: -6px;
  background-color: var(--colorgrad3);
  background-image: linear-gradient(
      45deg,
      var(--colorgrad1) 25%,
      transparent 25%,
      transparent 75%,
      var(--colorgrad1) 75%,
      var(--colorgrad1)
    ),
    linear-gradient(
      45deg,
      var(--colorgrad1) 25%,
      transparent 25%,
      transparent 75%,
      var(--colorgrad1) 75%,
      var(--colorgrad1)
    );
  background-size: 2px 2px;
  background-position: 0 0, 1px 1px;
}
.token_box:active,
.hasactive:active {
  box-shadow: inset -1px -1px 0 0 var(--colorgrad0),
    inset 1px 1px 0 0 var(--colorgrad7), inset -2px -2px 0 0 var(--colorgrad2),
    inset 2px 2px 0 0 var(--colorgrad3);
}
.token_rows {
  align-self: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}
.token_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 20px 20px 20px;
}

@font-face {
  font-family: leafery;
  src: url("assets/fonts/leafery.ttf");
}
@font-face {
  font-family: mohaw;
  src: url("assets/fonts/mohaw.otf");
}